import React from "react";
import NavBar from "../components/Navbar/NavBar";
import PropertyHero from "../components/Property/propertyhero";
import PropertyIntro from "../components/Property/propertyintro";
import PropertyListing from "../components/Property/PropertyListing";
import Footer from "../components/Footer";


function PropertiesPage() {
  return (
    <div>
      <NavBar />
      <PropertyHero/>
      {/* <PropertyListing/> */}
      {/* <PropertyIntro/> */}
      <Footer/>
      
      
    </div>
  );
}

export default PropertiesPage;
