import React from "react";
import { Link } from "react-router-dom";

const PropertyHero = () => {
  return (
    
      <div
        id="hero"
        className="propertyhero background: p-[100px] justify-between text-center lg:text-center "
      >
        <div
          className="lg:w-1/2 flex flex-col justify-center "
          data-aos="zoom-in"
          data-aos-delay="200"
        >
          <h1 className="mb-5 px-10 md:text-5xl text-3xl my-6 font-bold text-white">
            {/* We build digital solutions to help businesses scale */}
            "Find your home, Grow your Life <br /> Discover Al Ghaith <br />
            Communities"
          </h1>
        </div>
      </div>
    
  );
};

export default PropertyHero;
